




























































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import CardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { PageNum } from "@/tool/interface-index";
import SchoolGroupEditor from "@/views/mock-exam-project/components/school-group-editor.vue";
import {
  getTestPaperList,
  getTestPaperGroupGradeOptionList,
} from "@/api/testPaper";
import {
  TestPaperGroup,
  TestPaperGroupGradeOption,
  TestPaper,
  SchoolGroup,
  SchoolSchoolGroup,
  ResourceTemplateTestPaperUseAuth,
} from "@/tool/_class";
import * as _ from "lodash";
import {
  getSchoolCascadeList,
  getSchoolList,
  getSchoolGroupList,
  postSchoolGroup,
  deleteSchoolGroup,
  postSchoolSchoolGroup,
  deleteSchoolSchoolGroup,
} from "@/api/school";
import { UserModule } from "@/store/modules/user";
import {
  MessageBoxInputData,
  MessageBoxData,
} from "element-ui/types/message-box";
import * as TestPaperApi from "@/api/testPaper";
import SplitPane from 'vue-splitpane';
import { uuid } from "@/tool";

const UnGroupId = "000";

class TestPaperUseAuth {
  testPaperUseAuthId: string = "";
  testPaperId: string = "";
  schoolId: string = "";
  disabled: boolean = false;
}

@Component({
  name: "SchoolTestPaperListPage",
  components: {
    CardBox,
    pagination,
    SchoolGroupEditor,
    SplitPane
  },
  methods: {
    resize() {
      //
    }
  }
})
export default class extends Vue {
  private currentSchoolName: string = "";
  private activeTabName: string = "treeGroup";
  private activeCollapseName: string = "";
  private schools: any[] = [];
  private schoolGroups: SchoolGroup[] = [];

  private multipleSelection: object[] = [];

  private filterText: string = "";

  @Watch("filterText")
  private handleFilterTextChange(val: string) {
    (this.$refs.tree as any).filter(val)
  }

  private query: {
    keyWords: string;
    gradeNo?: string;
    grade?: string;
    term?: string;
    testPaperName?: string;
    cascadeSchoolId: string[];
  } = {
      gradeNo: "",
      keyWords: "",
      cascadeSchoolId: [],
    };

  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private grades: TestPaperGroupGradeOption[] = [];
  private tableData: object[] = [];
  private testPaperAuthList: TestPaperUseAuth[] = [];

  private get disabledOperation(): boolean {
    console.log(`roles:${UserModule.roles}`);
    if (this.$route.meta.roles) {
      let roled: boolean = false;
      for (let i = 0; i < this.$route.meta.roles.length; i++) {
        roled =
          roled ||
          UserModule.roles.some((item) => item == this.$route.meta.roles[i]);
        if (roled) {
          break;
        }
      }
      if (!roled) {
        return true;
      }
      console.log(`meta.roles:${this.$route.meta.roles},roled:${roled}`);

      if (
        UserModule.roles.some(
          (item) => item == "admin" || item == "platformAdmin"
        )
      ) {
        return false;
      }

      /*
      return (
        UserModule.roles.findIndex((role: string) => {
          return role.toLowerCase() == "teacher";
        }) >= 0
      );
      */
    }
    return true;
  }

  filterNode(value: string, data: any) {
    
    if (!value) return true;
    
    return data.keyValue.indexOf(value) !== -1;
  }

  private resizeSplit() {
    //
  }

  private queryTestPaperClick() {
    this.pageNum.curPage = 1;
    this.getTestPaperListData();
  }

  private upDataPage() {
    this.getTestPaperListData();
  }

  private handleSchoolGroupEditorClose(
    confirmed: boolean,
    school: SchoolSchoolGroup,
    schoolGroup: SchoolGroup
  ) {
    if (!confirmed) {
      return;
    }
    const group = this.schoolGroups.find((item) => {
      return item.schoolGroupId == schoolGroup.schoolGroupId;
    })!;
    for (let i = 0; i < this.schoolGroups.length; i++) {
      const idx = this.schoolGroups[i].schools.findIndex((item) => {
        return item.schoolId == school.schoolId;
      });
      if (idx >= 0) {
        const tmp = _.merge(this.schoolGroups[i].schools[idx], school);
        this.schoolGroups[i].schools.splice(idx, 1);
        group.schools.unshift(tmp);
        break;
      }
    }
    this.activeCollapseName = group.schoolGroupId;
  }

  private handleTabClick(tabName: string) {
    switch (tabName) {
      case "treeGroup": {
        break;
      }
      case "myGroup": {
        break;
      }
    }
  }

  private handleNodeClick(data: any, node: any) {
    if (data.dataType == "school") {
      this.setActiveSchool({
        schoolId: data.keyId,
        schoolName: data.keyValue,
      });
    } else {
      this.setActiveSchool();
    }
  }

  private loading: boolean = false;
  private async getTestPaperListData() {
    try {
      this.loading = true;
      const schoolId =
        this.query!.cascadeSchoolId.length > 0
          ? this.query!.cascadeSchoolId[0]
          : "";
      const res = await getTestPaperList({
        testPaperIds: (this.$route.query.testPaperIds as string[]) || [],
        gradeNo: this.query!.gradeNo || "",
        keyWords: this.query!.keyWords || "",
        schoolId,
        curPage: this.pageNum.curPage,
        pageSize: this.pageNum.pageSize,
      });
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
      this.pageNum.curPage = res.data.curPage;
      this.pageNum.pageSize = res.data.pageSize;
      //
      this.testPaperAuthList.splice(0, this.testPaperAuthList.length);
      const resps: any[] = await Promise.all([
        TestPaperApi.getTestPaperUseAuthList({
          curPage: 1,
          pageSize: 9999,
          schoolId,
          testPaperId:
            this.tableData.length == 1
              ? (this.tableData[0] as any).testPaperId
              : "",
          testPaperIds: (this.tableData as any[]).map(
            (item) => item.testPaperId
          ),
        }),
        TestPaperApi.getResourceTemplateTestPaperUseAuthList({
          curPage: 1,
          pageSize: 9999,
          schoolId,
          testPaperIds: (this.tableData as any[]).map(
            (item) => item.testPaperId
          ),
        }),
      ]);
      this.testPaperAuthList.push(
        ...resps[0].data.items.map((item: any) =>
          _.merge(new TestPaperUseAuth(), item)
        )
      );
      resps[1].data.items.forEach(
        (element: ResourceTemplateTestPaperUseAuth) => {
          const auths: TestPaperUseAuth[] = this.testPaperAuthList.filter(
            (item) => {
              return (
                element.schoolId == item.schoolId &&
                element.testPaperId == item.testPaperId
              );
            }
          );
          if (auths.length > 0) {
            //已存在
            auths.forEach((item) => {
              item.disabled = true;
            });
          } else {
            let auth = new TestPaperUseAuth();
            auth.disabled = true;
            this.testPaperAuthList.push(
              _.merge(auth, _.pick(element, ["schoolId", "testPaperId"]))
            );
          }
        }
      );
    } finally {
      this.loading = false;
    }
  }

  private setActiveSchool(
    school: { schoolId: string; schoolName: string } | null = null
  ) {
    this.query.cascadeSchoolId.splice(0, this.query.cascadeSchoolId.length);
    this.currentSchoolName = "";
    if (school) {
      this.currentSchoolName = school.schoolName;
      this.query.cascadeSchoolId.push(school.schoolId);
      this.pageNum.curPage = 1;
      this.getTestPaperListData();
    } else {
      this.tableData = [];
    }
  }

  private async doAddOrSetSchoolGroupClick(group: SchoolGroup) {
    try {
      const isNew: boolean = group.schoolGroupId == UnGroupId;
      if (isNew) {
      }

      const inputData: MessageBoxData = await this.$prompt(
        "请输入分组名",
        isNew ? "添加分组" : "修改组名",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValue: isNew ? "" : group.schoolGroupName,
          inputValidator: (value: string) => {
            if (!value) {
              return "请输入组名。";
            }
            return true;
          },
        }
      );
      let schoolGroup: SchoolGroup = new SchoolGroup();
      schoolGroup.schoolGroupName = (inputData as MessageBoxInputData).value;
      if (isNew) {
        const MD5 = require("md5.js");
        let md5: string = new MD5()
          .update(`${UserModule.userId}-${schoolGroup.schoolGroupName}`)
          .digest("hex");
        schoolGroup.schoolGroupId = md5;
      } else {
        _.merge(schoolGroup, _.pick(group, ["schoolGroupId", "userId"]));
      }
      await postSchoolGroup(schoolGroup);
      if (isNew) {
        this.schoolGroups.unshift(schoolGroup);
      } else {
        group.schoolGroupName = schoolGroup.schoolGroupName;
      }
      this.activeCollapseName = schoolGroup.schoolGroupId;
    } catch (error) {
    } finally {
    }
  }

  private async doDeleteSchoolGroupClick(group: SchoolGroup) {
    try {
      await this.$confirm(
        `删除分组“${group.schoolGroupName}”, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      let i: number = 0;
      while (i < group.schools.length) {
        await deleteSchoolSchoolGroup(group.schools[i].schoolSchoolGroupId);
        const school = group.schools.pop()!;
        school.schoolGroupId = UnGroupId;
        this.schoolGroups[0].schools.push(school);
      }
      await deleteSchoolGroup(group.schoolGroupId);
    } catch (error) {
      if (typeof error == "string" && error == "cancel") {
        return;
      }
    } finally {
      //
    }
  }

  private doChangeSchoolGroup(school: SchoolSchoolGroup, group: SchoolGroup) {
    (this.$refs.schoolGroupEditor as SchoolGroupEditor).show(
      school,
      this.schoolGroups.map((item) => {
        let tmp: SchoolGroup = new SchoolGroup();
        return _.merge(
          tmp,
          _.pick(item, ["schoolGroupId", "schoolGroupName", "userId"])
        );
      })
    );
  }

  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "school": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            cascades.splice(i, 1);
          } else {
            this.removeNullChildren(cascades[i].children);
            i++;
          }
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  private gradeFormatter(row: any, column: any) {
    const idx = this.grades.findIndex((item: any) => {
      return item.gradeNo == row.gradeNo;
    });
    if (idx >= 0) {
      return `${this.grades[idx].grade}[${row.term02}]`;
    } else {
      return `${row.grade}[${row.term02}]`;
    }
  }
  private standardAnswerFormatter(row: any, column: any) {
    if (row.questionCount) {
      return `${row.standardAnswerCount} / ${row.questionCount}`;
    } else {
      return "0";
    }
  }

  private authSelectable(row: any, index: number) {
    // return false;
    return !this.testPaperAuthList.some(
      (item) => item.testPaperId == row.testPaperId && item.disabled
    );
  }

  private doUseAuthorizeClick(testPaperId: string) {
    this.$router.push({
      path: "testPaperUseAuth",
      query: {
        testPaperIds: [testPaperId],
        useAuthTo: "right",
      },
    });
  }


  private async doDeleteUseAuthorize(
    testPaperIds: string[],
    schoolIds: string[]
  ) {
    const direction: string = "left";
    for (let i = 0; i < testPaperIds.length; i++) {
      let testPaperId: string = testPaperIds[i];
      for (let k = 0; k < schoolIds.length; k++) {
        const { data } = await TestPaperApi.getTestPaperUseAuthList({
          curPage: 1,
          pageSize: 1,
          testPaperId: testPaperId,
          schoolId: schoolIds[k],
        });

        switch (direction) {
          case "left": {
            if (data.items.length > 0) {
              await TestPaperApi.delTestPaperUseAuth(
                data.items[0].testPaperUseAuthId
              );
            }
            break;
          }
          case "right": {
            //此授权代码留着，暂用不上
            if (data.items.length <= 0) {
              let auth: TestPaperUseAuth = new TestPaperUseAuth();
              auth.testPaperUseAuthId = uuid();
              auth.testPaperId = testPaperId;
              auth.schoolId = schoolIds[k];
              await TestPaperApi.postTestPaperUseAuth(auth);
            }
            break;
          }
        } //switch
      } //for k
    } //for i
  }

  private async doDeleteUseAuthorizeClick(testPaperId: string) {
    if (!testPaperId) {
      this.$message({
        message: "请先选择试卷。",
        type: "warning",
      });
      return;
    }
    if (!this.query.cascadeSchoolId.length) {
      this.$message({
        message: "请先选择学校。",
        type: "warning",
      });
      return;
    }
    await this.$confirm("取消授权, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
    await this.doDeleteUseAuthorize([testPaperId], [_.last(this.query.cascadeSchoolId) as string]);
    await this.getTestPaperListData();
    //alert(this.query.cascadeSchoolId + ','+ testPaperId);
  }

  private async doDeleteUseAuthorizeBatchClick() {
    if (this.multipleSelection.length == 0) {
      this.$message({
        message: "请先选择试卷。",
        type: "warning",
      });
      return;
    }
    if (!this.query.cascadeSchoolId.length) {
      this.$message({
        message: "请先选择学校。",
        type: "warning",
      });
      return;
    }
    await this.$confirm("批量取消授权, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
    let testPaperIds: string[] = [];
    for (let i = 0; i < this.multipleSelection.length; i++) {
      testPaperIds.push(
        (this.multipleSelection[i] as any).testPaperId as string
      );
    }
    await this.doDeleteUseAuthorize(testPaperIds, [_.last(this.query.cascadeSchoolId) as string]);
    await this.getTestPaperListData();
  }

  doUseAuthToClick(useAuthTo: string) {
    if (this.multipleSelection.length == 0) {
      this.$message({
        message: "请先选择试卷。",
        type: "warning",
      });
      return;
    }
    let testPaperIds: string[] = [];
    for (let i = 0; i < this.multipleSelection.length; i++) {
      testPaperIds.push(
        (this.multipleSelection[i] as any).testPaperId as string
      );
    }
    console.log(`testPaperIds:${JSON.stringify(testPaperIds)}`);
    this.$router.push({
      path: "testPaperUseAuth",
      query: {
        testPaperIds: testPaperIds,
        useAuthTo: useAuthTo,
      },
    });
  }

  handleSelectionChange(val: object[]) {
    this.multipleSelection = val;
  }

  private areas(schools: SchoolSchoolGroup[]) {
    return _.uniqWith(
      schools.map((item) => {
        return {
          areaId: item.areaId,
          areaName: item.areaName || item.areaId,
        };
      }),
      (arrVal, othVal) => {
        return arrVal.areaId == othVal.areaId;
      }
    );
  }

  private __init() {
    this.grades.splice(0, this.grades.length);
    getTestPaperGroupGradeOptionList().then(({ data }) => {
      data.items.forEach((element: TestPaperGroupGradeOption) => {
        this.grades.push(element);
      });
    });

    getSchoolCascadeList().then(({ data }) => {
      this.schools = data.items;
      this.removeNullChildren(this.schools);
    });
    getSchoolGroupList({
      mergeSchools: true,
      curPage: 1,
      pageSize: 10000,
    }).then(({ data }) => {
      this.schoolGroups = data.items;
    });
  }

  mounted() {
    //this.getExamStudentListData();
    this.__init();
  }
}
