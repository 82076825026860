











































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import * as _ from "lodash";
import { Form } from "element-ui";
import { SchoolGroup, SchoolSchoolGroup } from "@/tool/_class";
import {
  getSchoolCascadeList,
  getSchoolList,
  getSchoolGroupList,
  postSchoolGroup,
  postSchoolSchoolGroup,
} from "@/api/school";

const UnGroupId = "000";

@Component({
  name: "SchoolGroupEditor",
})
export default class extends Vue {
  @Emit("close") onClose(
    confirmed: boolean,
    school: SchoolSchoolGroup,
    schoolGroup: SchoolGroup
  ) {
    this.dialogVisible = false;
  }
  private formName: string = "schoolGroupForm";
  private formData: SchoolSchoolGroup = new SchoolSchoolGroup();
  private schoolGroups: SchoolGroup[] = [];
  private dialogVisible: boolean = false;

  private get title(): string {
    return "学校分组设置";
  }

  private rules: any = {
    schoolGroupId: [
      {
        required: true,
        message: "请选择分组",
        trigger: "blur",
      },
    ],
  };

  show(school: SchoolSchoolGroup, schoolGroups: SchoolGroup[]) {
    //alert(JSON.stringify(school))
    this.schoolGroups = schoolGroups;
    this.formData = new SchoolSchoolGroup();
    _.merge(this.formData, school);
    this.dialogVisible = true;
    (this.$refs["schoolGroupForm"] as Form).resetFields();
  }
  private handleOpen() {}

  private handleClose() {
    this.onClose(
      false,
      this.formData,
      this.schoolGroups.find((item) => {
        return item.schoolGroupId == this.formData.schoolGroupId;
      })!
    );
  }

  private doCancelClick() {
    this.onClose(
      false,
      this.formData,
      this.schoolGroups.find((item) => {
        return item.schoolGroupId == this.formData.schoolGroupId;
      })!
    );
  }

  private async doConfirmClick(formName: string) {
    try {
      await (this.$refs[formName] as any).validate();
      if (!this.formData.schoolSchoolGroupId) {
        const MD5 = require("md5.js");
        let md5: string = new MD5()
          .update(`${this.formData.schoolGroupId}-${this.formData.schoolId}`)
          .digest("hex");
        this.formData.schoolSchoolGroupId = md5;
      }

      await postSchoolSchoolGroup(this.formData);
      this.onClose(
        true,
        this.formData,
        this.schoolGroups.find((item) => {
          return item.schoolGroupId == this.formData.schoolGroupId;
        })!
      );
      //
      this.$message({
        message: "保存成功。",
        type: "success",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
    } catch (error) {
      if (typeof error == "boolean") {
        //validate()的返回
        return;
      }
      let resp: any = (error as any)?.response || null;
      let msg: string = "";
      if (resp) {
        msg = resp!.msg;
      } else {
        msg = (error as any).message;
      }
      this.$message({
        message: msg,
        type: "error",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
    } finally {
    }
  }

  mounted() {}
}
